<template>
  <div class="newlist">
    <el-card style="margin: 16px 22px; border-radius: 12px">
      <div>
        <span class="new-tip">{{ type ? '编辑活动' : '新增活动' }}</span>
        <div class="new-line"></div>
      </div>


      <div style="font-weight: 500; font-size: 18px;padding-top: 24px;padding-bottom: 16px;">活动信息</div>

      <el-form :model="newForm" :rules="formRules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="活动名称" prop="activityName">
          <el-input style="width: 450px" v-model="newForm.activityName" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="参与人" prop="participant">
          <el-input style="width: 450px" v-model="newForm.participant" placeholder="请输入参与人"></el-input>
        </el-form-item>
        <el-form-item label="参与方式" prop="isParticipate ">
          <el-radio-group v-model="newForm.isParticipate">
            <el-radio label="线上">线上</el-radio>
            <el-radio label="线下">线下</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="活动城市" prop="region" v-if="newForm.isParticipate === '线下'">
          <cascader-area ref="areaRef" :defaultValue="false" country="中国" :province.sync="newForm.province"
            :city.sync="newForm.city" @getArea="getArea" />

        </el-form-item>
        <el-form-item label="活动地址" v-if="newForm.isParticipate === '线下'" prop="activityAddress">

          <el-input style="width: 450px" v-model="newForm.activityAddress" placeholder="请输入活动详细地址"></el-input>
        </el-form-item>
        <el-form-item label="活动时间">
          <div class="timePicker">
            <el-form-item prop="startTime">
              <el-date-picker type="datetime" placeholder="开始时间" v-model="newForm.startTime" class="middle-date-picker"
                format="yyyy-MM-dd HH:mm" style="width:100%" value-format="yyyy-MM-dd HH:mm" />
            </el-form-item>
            <span style="padding: 0px 10px">~</span>
            <el-form-item prop="endTime">
              <el-date-picker type="datetime" placeholder="结束时间" v-model="newForm.endTime" class="middle-date-picker"
                format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" />
            </el-form-item>
          </div>
        </el-form-item>


        <el-form-item label="参与用户" prop="joinCustomer">
          <el-radio-group v-model="newForm.joinCustomer">
            <el-radio label="0">全部用户</el-radio>
            <el-radio label="1">会员用户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="活动名额">
          <el-input style="width: 182px" v-model="newForm.people" placeholder="请输入人数"></el-input>
          <span style="margin-left: 34px">收费方式</span>
          <el-select style="width: 180px; margin-left: 6px" v-model="newForm.chargeWay" placeholder="免费/付费">

            <el-option v-for="item in options" :key="item.chargeWay" :label="item.label" :value="item.chargeWay">

            </el-option>
          </el-select>
        </el-form-item>

        <div v-for="(item, index) in newForm.activityPriceList" :key="index" style="position: relative">
          <el-row>

            <el-col :span="5">

              <el-form-item label="票名">
                <el-input style="width: 182px" v-model="item.ticketName" placeholder="请输入票名"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item label="票数">
                <el-input style="width: 182px; margin-left: 6px" v-model="item.votes" placeholder="请输入票数">
                </el-input>
                <el-button :class="index == 0 ? 'icon-button' : 'icon-delete'"
                  :icon="index == 0 ? 'el-icon-plus' : 'el-icon-minus'" circle size="mini" @click="addList(index)">
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-form-item label="是否免费" prop="resource">
                <el-radio-group v-model="item.isMoney">
                  <el-radio label="0">是</el-radio>
                  <el-radio label="1">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="票价">
                <el-input style="width: 182px; margin-left: 6px" v-model="item.price" placeholder="请输入票价">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="是否单人单张" prop="resource">
                <el-radio-group v-model="item.isSingle">
                  <el-radio label="0">是</el-radio>
                  <el-radio label="1">否</el-radio>
                  <span class="radio-tip">(若不是单人单张，则一人最多购买10张)</span>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row>

            <el-col :span="5">
              <el-form-item label="会员价">
                <el-input style="width: 182px" v-model="item.memberPrice" placeholder="请输入票价"></el-input>

              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="会员价折扣">
                <el-input style="width: 182px; margin-left: 6px" v-model="item.memberDiscount" placeholder="请输入折扣">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              <el-form-item label="备注">
                <el-input style="width: 450px" v-model="item.notes" placeholder="请输入备注"></el-input>
              </el-form-item>
            </el-col>

          </el-row>

        </div>
        <span>主办方信息</span>
        <el-row>
          <el-col :span="24">
            <el-form-item label="主办方名称 " prop="companyName">
              <el-input style="width: 450px" v-model="newForm.companyName" placeholder="请输入主办方名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <span>活动详情</span>
        <el-row>
          <el-col :span="24">
            <el-form-item label="活动封面图" prop="activityCover">
              <div style="color: #7c71e1; font-size: 12px">
                <upload-image-one :image='newForm.activityCover' :imageShow="newForm.activityCover"
                  :addTitleShow="false" @updateImgOne="updateImgOne" />
                (建议整体尺寸为650px*352px，图片格式应为jpg、jpeg、png，图片应小于12MB)
              </div>
              <!-- <el-dialog>
            <img width="100%" :src="newForm.activityPoster" alt="" />
          </el-dialog> -->
            </el-form-item>
          </el-col>
       </el-row>

        <el-form-item label="活动描述" prop="activityDescribe">
          <div class="bottom_tip">
            (图片的建议尺寸长为800px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB)
          </div>
          <div class="editBar">
            <editorbar v-model="newForm.activityDescribe" :isClear="isClear" />
          </div>

        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" style="width: 136px" @click="save">保存</el-button>
        <el-button style="width: 136px" @click="cancel">取消</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import { detailList, getAddList, getUpdateList } from '@/api/activity.js'
import CascaderArea from '../../components/CascaderArea.vue'
import UploadImageOne from '../../components/UploadImageOne.vue'
import Editorbar from '../../components/Editorbar.vue';
export default {
  name: 'newList',
  components: {
    CascaderArea,
    UploadImageOne,
    Editorbar
  },
  data() {
    return {
      imageList: [],
      value: '',
      newForm: {
        country: '中国',
        activityDescribe: '',
        activityCover: '',
        activityAddress: '',
        activityName: '',
        activityPoster: '',
        activityPriceList: [
          {
            activityId: 0,
            createTime: '',
            id: 0,

            isMoney: '',
            isSingle: '',
            memberDiscount: 0,
            notes: '',
            price: 0,
            sum: 0,
            ticketName: '',
            updateTime: '',
            votes: 0
          }
        ],
        chargeWay: '',
        city: '',
        companyName: '',
        createId: 0,
        createTime: '',
        endTime: '',
        id: 0,
        isParticipate: '',
        isSued: '0',
        joinCustomer: '',
        participant: '',
        people: 0,
        province: '',
        startTime: '',
        updateId: 0,
        updateTime: ''
      },
      chargeWay: '',
      isClear: false,
      formRules: {
        activityName: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        isParticipate: [{ required: true, message: '请选择参与方式', trigger: 'change' }],
        // times: [
        //     { required: true, message: "请选择开始时间", trigger: "change" },
        // ],
        joinCustomer: [{ required: true, message: '请选择参与用户', trigger: 'change' }],
        companyName: [{ required: true, message: '请输入主办方名称', trigger: 'blur' }],
        activityDescribe: [
          { required: true, message: "请输入活动描述", trigger: "blur" },
        ],
        activityCover: [
          { required: true, message: "请输入活动封面图", trigger: "blur" },
        ]

      },
      cities: [
        {
          value: '选项1',
          label: '黄金糕'
        }
      ],
      options: [
        {
          value: '免费',
          label: '免费'
        },
        {
          value: '付费',
          label: '付费'
        }
      ],
      times: ''
    }
  },
  computed: {
    id() {
      return this.$route.query.id * 1
    },
    type() {
      return this.$route.query.activityInfoType * 1
    }
  },
  async created() {
    if (this.type) {
      const { data: res } = await detailList({ id: this.id })
      Object.assign(this.newForm, res.data)
    }
  },
  methods: {
    getArea({ province, city }) {
      this.newForm.province = province
      this.newForm.city = city
    },
    uploadOneImgShow(val) {
      this.newForm.activityCover = val
    },
    updateImgOne(val) {
      this.newForm.activityCover = val;
    },
    save() {
      /**
       * 保存方法
       */
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.type) {
            const { data: res } = await getUpdateList(this.newForm)
            if (res.resultCode === 200) {
              this.$message.success('编辑成功！')
              this.$router.go(-1)
            } else {
              this.$message.success('编辑失败，请稍候重试')
            }
          } else {
            const { data: res } = await getAddList(this.newForm)
            if (res.resultCode === 200) {
              this.$message.success('新增成功！')
              this.$router.go(-1)
            } else {
              this.$message.success('新增失败，请稍候重试')
            }
          }
        } else {
          this.$message.warning('请完善基本信息')
        }
      })
    },
    addList(index) {
      /**
       * +-按钮点击e
       * @param {*} index
       */
      //下标大于0时，是去除整个表单
      if (index > 0) {
        this.newForm.activityPriceList.splice(index, 1)
      } else {
        //新增一条数据，页面新增一组表单
        this.newForm.activityPriceList.push({
          activityId: 0,
          createTime: '',
          id: 0,
          isClick: '',
          isMoney: '',
          isSingle: '',
          memberDiscount: 0,
          notes: '',
          price: 0,
          sum: 0,
          ticketName: '',
          updateTime: '',
          votes: 0
        })
      }
    },
    handlePictureCardPreview() { },
    handleRemove() { },
    dateChange(value) {
      /**
       * 日期控件切换方法
       * @param {} value
       */
      this.newForm.startTime = value[0]
      this.newForm.endTime = value[1]
    },
    //取消
    cancel() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.newlist {
  width: 100%;
  overflow: auto;

  /deep/.reference_img {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .reference_img_auto {
    max-width: 100%;
    max-height: 800px;
    overflow: auto;
  }

  .reference_div {
    width: 200px;
    height: 200px;
  }

  .reference_div_new {
    width: 200px;
    height: 200px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    border: 1px dashed #eee;
    width: 120px;
    line-height: 120px;
    height: 120px;
    text-align: center;
  }

  .avatar {
    width: 120px;
    height: 120px;
    display: block;
  }

  .addinfo_title {
    font-size: 14px;
    color: #999;
  }

  .new-tip {
    font-size: 18px;
    color: #968de2;
  }

  .new-line {
    width: 100%;
    height: 0px;
    border: 1px solid #efefef;
    margin-top: 15px;
  }

  .icon-button {

    margin-left: 10px;
    border: 1px solid #65B471;
    color: #65B471;
  }

  .icon-delete {
    margin-left: 10px;
    border: 1px solid #FF4444;
    color: #FF4444
  }

  .radio-tip {
    font-weight: 400;
    font-size: 10px;
    color: #DCDCDC;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #7C71E1;
    background: #7C71E1
  }

  .startime {
    margin-right: 0;
  }

  .editBar {
    width: 800px;
  }

  .bottom_tip {
    color: #7c71e1;
    font-size: 12px;
    margin-top: 20px;
  }

}
</style>
